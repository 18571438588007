import React, { useState, useEffect, useRef } from 'react'
import { BiSend } from 'react-icons/bi'
import { ThreeDots } from 'react-loader-spinner'
import { AiFillCloseCircle } from 'react-icons/ai'
import ChatMessage from '../components/ChatMessage'
import robot from '../assest/bertie_a_2D_cartoon_robot_futuristic_fun_black_and_orange_in_a__b610104d-c368-4e8e-bc2e-13a126498a58-removebg-preview.png'
import reach from '../assest/images-removebg-preview.png'


const Chat = () => {
  const [input, setInput] = useState()
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(true)
  const [chatLog, setChatLog] = useState([
    {
      user: 'gpt',
      message: 'Hello I am ROAN. I stand for Reach OnAir Notes. Here to help you with content, ideas and general notes for your next radio show. Why don\'t you tell me about your show and then we can discuss some ideas together?'
    }
  ]);




  console.log(chatLog)
  useEffect(() => {
    localStorage.setItem('chatLogStored', JSON.stringify(chatLog));
  }, [chatLog])


  useEffect(() => {
    const clearStorage = setTimeout(() => {
      localStorage.clear();
    }, 86400);

    return () => {
      clearTimeout(clearStorage);
    };
  }, []);

  const bottomRef = useRef(null);
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatLog]);

  async function handleSubmit(e) {
    e.preventDefault();
    if (input.trim()) {
      let chatLogNew = [...chatLog, { user: 'me', message: `${input}` }]

      const message = input;
      const history_data = localStorage.getItem('chatLogStored')
      console.log(input)

      setInput("")
      setChatLog(chatLogNew)
      setLoading(true)

      const response = await fetch('https://roan.herokuapp.com/api/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          message, //: messages
          history_data
        })
      }).catch(error => { setLoading(false); console.log('Error!') });
      const data = await response.json();
      setChatLog([...chatLogNew, { user: 'gpt', message: `${data.data.message}` }])
      // localStorage.setItem('chatLog', JSON.stringify(chatLog));
      setLoading(false)
      console.log(data.data.message)
      console.log(`History Data: ${history_data}`)
    }
  }




  return (
    <div className='h-screen'>

      {showModal && <div className="bg-slate-800 z-50 bg-opacity-50 flex md:hidden justify-center items-center absolute top-0 right-0 bottom-0 left-0">
        <div className="bg-white px-4 py-4 rounded-3xl text-center w-[90%]">
          <div className='flex justify-between items-center py-4'>
            <p className='font-bold text-xl'>Disclaimer</p>
            <div><AiFillCloseCircle color='#222222' size='1.8rem' onClick={() => setShowModal(false)} /></div>
          </div>
          <div>
            <p className=''>The content and resources provided by ReachOnAir Notes (ROAN) are intended for informational and entertainment purposes only. Reach Broadcasting Ltd does not endorse or take responsibility for the accuracy, completeness, or reliability of any information, ideas, or materials provided through ROAN. Furthermore, Reach Broadcasting Ltd shall not be held liable for any errors or omissions in the content, nor for any actions taken by users based on the content.</p>
          </div>
        </div>
      </div>}
      <div>
        <div className='h-[10vh] flex justify-center items-center' style={{ backgroundColor: "#222222" }}><a href='https://reachonair.com/'><img src={reach} alt='logo' style={{ height: "200px" }} /></a></div>

        <div className='flex'>
          <div style={{ backgroundColor: "#222222" }} className='w-[30%] h-[80vh] px-4 hidden md:block overflow-y-auto'>
            <div className='h-[65%] w-full flex justify-center'><img src={robot} alt='robot' /></div>
            <div className=''>
              <p className='text-white'><span className='font-semibold'>Disclaimer:</span> The content and resources provided by ReachOnAir Notes (ROAN) are intended for informational and entertainment purposes only. Reach Broadcasting Ltd does not endorse or take responsibility for the accuracy, completeness, or reliability of any information, ideas, or materials provided through ROAN. Furthermore, Reach Broadcasting Ltd shall not be held liable for any errors or omissions in the content, nor for any actions taken by users based on the content.</p>
            </div>
          </div>
          <div className=' w-full md:w-[70%] h-[80vh] flex flex-col justify-between'>
            <div className='h-[80vh] flex justify-center relative' style={{ backgroundColor: "#d8d9dc" }}>
              <div className='w-full px-[4%] overflow-y-auto absolute bottom-0' style={{ maxHeight: '100%' }}>
                {chatLog.map((message, index) => (
                  <ChatMessage key={index} message={message} />
                ))}
                {loading && <div><ThreeDots
                  height="20"
                  width="80"
                  radius="9"
                  color="#282c34"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                /></div>}
                <div ref={bottomRef} />
              </div>
            </div>
            <div className='h-[10vh] py-4 ' style={{ backgroundColor: "#d8d9dc" }}>
              <form onSubmit={handleSubmit} className='flex justify-center'>
                <div className='flex justify-center items-center bg-white w-[80%] border-2 border rounded-xl pr-2' style={{ border: "2px solid #e49000" }}>
                  <input type='text' value={input} onChange={(e) => setInput(e.target.value)} placeholder='Say hi or ask a question!' className='w-[100%] h-[40px] rounded-xl px-2' style={{ outline: 'none' }} />
                  <button type='submit'><BiSend size='1.8rem' style={{ color: "#e49000" }} /></button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className='h-[10vh] px-4 hidden md:flex w-full justify-end items-center' style={{ backgroundColor: "#222222" }}>
          <div className='w-[50%] flex'>
            <p className=' w-[50%] text-white font-semibold'>Reach Broadcasting Ltd &copy; 2023</p>
            <div className='flex w-[50%] text-right cursor-pointer'><p onClick={() => setChatLog([...chatLog,
            {
              user: 'gpt',
              message: 'As ROAN, I can help you with many things, for example: \n\n1. Tailored content ideas and "on this day" \n\n2. Engaging games and interactive segments.\n\n3. Example listener feedback for discussions starters and practice\n\n4. Jokes and humour to lighten up your show.\n\n5. Unique stories and quizzes\n\n These are just a few example! Just tell me what you need and I will help enhance your radio show and keep your listeners engaged. Please note my music and trivia knowledge may be limited to before 2022 at this time.'
            }
            ])} className='w-full text-white'>What Can I Do?</p></div>
          </div>
        </div>
        <div className='h-[10vh] justify-evenly gap-4 flex md:hidden items-center w-full' style={{ backgroundColor: "#222222" }}>
          <div><a href='https://reachonair.com/'><img src={reach} width={100} height={70} alt='logo' /></a></div>
          <div><p onClick={() => setChatLog([...chatLog,
          {
            user: 'gpt',
            message: 'As ROAN, I can help you with many things, for example: \n1. Tailored content ideas and "on this day" \n2. Engaging games and interactive segments.\n3. Example listener feedback for discussions starters and practice\n4. Jokes and humour to lighten up your show.\n5. Unique stories and quizzes\n These are just a few example! Just tell me what you need and I will help enhance your radio show and keep your listeners engaged. Please note my music and trivia knowledge may be limited to before 2022 at this time.'
          }
          ])} className='text-center w-full text-white'>What Can I Do?</p></div>
          <div><p onClick={() => setChatLog([...chatLog,
          {
            user: 'gpt',
            message: 'The content and resources provided by ReachOnAir Notes (ROAN) are intended for informational and entertainment purposes only. Reach Broadcasting Ltd does not endorse or take responsibility for the accuracy, completeness, or reliability of any information, ideas, or materials provided through ROAN. Furthermore, Reach Broadcasting Ltd shall not be held liable for any errors or omissions in the content, nor for any actions taken by users based on the content.'
          }
          ])} className='text-center w-full text-white'>Disclaimer</p></div>
          {/* <div className='flex gap-2 text-center text-white' onClick={() => setShowModal(true)}><p>Disclaimer</p></div> */}
        </div>
      </div>
    </div >
  )
}

export default Chat