import React, { useState, useEffect } from 'react'
import './App.css';
import { Routes, Route, useParams } from 'react-router-dom';
import Chat from './pages/Chat';
import Admin from './pages/Admin';
import Edit from './pages/Edit';
import axios from 'axios';

function App() {
  const [logina, setLogina] = useState(false)
  const { access_key } = useParams();

  useEffect(() => {
    const key = JSON.stringify(access_key);

    let config = {
      method: 'post',
      url: 'https://reachonair.com/ext-api/v1/validate',
      headers: {
        'Content-Type': 'application/json'
      },
      data: key
    };

    axios(config)
      .then((response) => {
        if (response.status === 204) {
          const url = `https://roan.reachonair.com/?access_key=${access_key}`;
          window.history.pushState({}, '', url);
        } else {
          window.location.href = 'https://reachonair.com/login';
        }
      })
      .catch((error) => {
        console.error('Error validating access key:', error);
        window.location.href = 'https://reachonair.com/login';
      });

  });

  return (
    <>
      <Routes>
        <Route path='/' element={<Chat />}></Route>
        <Route path='/admin' element={<Admin logina={logina} setLogina={setLogina} />}></Route>
        <Route path='/edit' element={<Edit logina={logina} setLogina={setLogina} />}></Route>
      </Routes>
    </>
  );
}

export default App;
