import React from 'react'

const Footer = () => {
  return (
    <>
      <div className='h-[10vh] px-4 flex items-center justify-between w-full' style={{ backgroundColor: '#222222' }}>
        <p className='text-center w-full text-white font-semibold'>Reach Broadcasting Ltd &copy; 2023</p>
      </div>
    </>
  )
}

export default Footer