import React from 'react'
import reach from '../assest/images-removebg-preview.png'

const Header = () => {
  return (
    <div className='flex justify-center h-[10vh]' >
      <img src={reach} alt='logo' width={150} height={200} />
    </div>
  )
}

export default Header